@import 'reset';

html {
  /* If we change this using a responsive REM approach, it will modify the starting sizes of all implementations that use rem in our application */
  font-size: 93.75%; /* // most browsers default to 1rem; */
}

/* Extra small devices (phones, 600px and down) */

@media screen and (min-width: 1920px) {

  html {
      font-size: 22px;
  }
}

@media screen and (max-width: 1920px) {

  html {
      font-size: 18px;
  }
}

@media screen and (max-width: 1560px) {

  html {
      font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {

  html {
      font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  html {
      font-size: 13px;
  }
}

@media screen and (max-width: 768px) {

  html {
      font-size: 12px;
  }
}


@media screen and (max-width: 576px) {
  html {
      font-size: 10px;
  }
}



@import 'node_modules/bootstrap/scss/functions';
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// 2. Include any default variable overrides here
// Default variable overrides
$body-bg: #23222a;
$body-color: #23222a;
$font-family-sans-serif:      "Tahoma" !default;
$font-family-monospace:       "Tahoma" !default;
$grid-gutter-width:           0rem !default;
$container-max-widths: (
  sm: 33.75rem,
  md: 45rem,
  lg: 60rem,
  xl: 71.25rem,
  xxl: 82.5rem,
  xxxl: 97.5rem
) !default;
$grid-breakpoints: (
  xs: 0,
  sm: 36rem,
  md: 48rem,
  lg: 62rem,
  xl: 75rem,
  xxl: 87.5rem,
  xxxl: 100rem
);

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/grid';
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/placeholders";
@import 'node_modules/bootstrap/scss/helpers';



// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import 'node_modules/bootstrap/scss/utilities/api';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
